
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "0c106f90-6853-4c9b-ab53-425bdc1bc34a"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/app/node_modules/@healthpilot/nuxt-base-layer/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
